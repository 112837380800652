<template>
  <nuxt-link
    :to="venue.slug"
    class="w-full p-5 cursor-pointer md:mb-5"
    :class="{
      'md:w-1/2 lg:w-1/3': !fullWidth,
    }"
  >
    <div class="rounded-t-5">
      <HtgPic sizes="(min-width: 1024px) calc(100vw / 3), (min-width: 768px) calc(100vw / 2), 100vw" :src="venue.primaryImageUrl" loading="lazy" :alt="venue.name" cssClasses="block w-full h-56 bg-center bg-cover rounded-5 object-cover" />
      <div class="w-full flex flex-col mt-2.5">
        <div class="flex items-top">
          <div class="block w-8 h-8 bg-center bg-cover mr-1" v-bind:style="{ 'background-image': 'url(' + require(`~/assets/img/category/${venue.categoryId}.svg`) + ')' }"></div>
          <div class="flex flex-col" style="width: calc(100% - 36px)">
            <span class="text-secondaryBlack font-sans-lato text-lg leading-tight truncate w-full">{{ venue.name }}</span>
            <span class="text-textGray font-sans-lato text-xs leading-tight">{{ venue.formattedAddress }}</span>
          </div>
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import HtgPic from '@/components/global/HtgPic.vue'

export default {
  props: ['venue', 'fullWidth'],

  components: {
    HtgPic,
  },
}
</script>
