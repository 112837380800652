<template>
    <div class="flex flex-col justify-center items-center w-full p-4 mx-auto max-w-screen-xl mt-0 mb-7.5 md:mt-10 md:mb-20 md:flex-row">
        <div class="flex items-center">
            <img src="~/assets/img/home/hero.svg" class="w-50 md:w-auto" alt="Beer and Metal"/>
        </div>
        <div class="flex flex-col max-w-175 md:ml-25">
            <h1 class="text-secondaryBlack font-bold font-sans-lato leading-tight text-3xl md:text-4xl lg:text-6xl">Discover your next favorite place to bang your head.</h1>
            <span class="leading-tight text-placeholderGray text-xl text-textGray font-sans-lato mt-5 w-full lg:w-3/4 md:leading-normal">Headbangers Travel Guide is a community of metal enthusiasts sharing their favorite bars, clubs & shops from around the world.</span>

            <div class="items-center mt-6 hidden md:flex">
                <nuxt-link :to="'/map'" class="cursor-pointer rounded bg-primaryGreen font-sans-montserrat py-3 text-white uppercase text-sm px-4 lg:px-8 lg:text-base">Find Venues</nuxt-link>
                <nuxt-link :to="'/venue/new'" class="ml-5 rounded bg-transparent font-sans-montserrat py-3 text-primaryGreen border border-primaryGreen uppercase text-sm px-4 lg:px-8 lg:text-base">Add Your Favorite Venues</nuxt-link>
            </div>
            <!-- <div class="items-center mt-5 flex">
                <a href="#" onClick="alert('TODO: Add link when done')" rel="noopener" target="_blank">
                    <img src="~/assets/img/logos/app-store.svg" class="h-10 mr-2" alt="Download on the App Store"/>
                </a>

                <a href="#" onClick="alert('TODO: Add link when done')" rel="noopener" target="_blank">
                    <img src="~/assets/img/logos/google-play-store.svg" class="h-10" alt="Get it on Google Play"/>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>
