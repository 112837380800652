<template>
  <img v-if="isHtg" :src="getHtgImgUrl(src, 1536, 'jpg')" :srcset="getHtgImgUrls(src)" :sizes="sizes"
    :class="cssClasses" :loading="loading" :alt="alt" />
  <img v-else :class="cssClasses" :loading="loading" :src="src" :alt="alt" />
</template>
<script>
export default {
  props: {
    src: null,
    alt: null,
    cssClasses: null,
    loading: null,
    sizes: null,
  },

  methods: {
    getHtgWebpImgUrls(url) {
      return this.getHtgImgUrlWithSize(url, 320, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 640, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 768, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1024, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1280, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1536, 'webp')
    },

    getHtgJpgImgUrls(url) {
      return this.getHtgImgUrlWithSize(url, 320, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 640, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 768, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1024, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1280, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1536, 'jpg')
    },

    getHtgImgUrls(url) {
      return this.getHtgImgUrlWithSize(url, 320, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 640, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 768, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1024, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1280, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 1536, 'webp') + ', ' + this.getHtgImgUrlWithSize(url, 320, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 640, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 768, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1024, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1280, 'jpg') + ', ' + this.getHtgImgUrlWithSize(url, 1536, 'jpg')
    },

    getHtgImgUrlWithSize(url, size, type) {
      return this.getHtgImgUrl(url, size, type) + ' ' + size + 'w'
    },

    getHtgImgUrl(url, size, type) {
      return url.replace('[[SIZE]]', size).replace('[[TYPE]]', type)
    },
  },

  computed: {
    isHtg() {
      return this.src.toLowerCase().includes(this.$config.BASE_URL.toLowerCase()) && this.src.toLowerCase().includes('[[size]].[[type]]')
    },
  },
}
</script>
