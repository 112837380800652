<template>
  <div id="home" class="page bg-white min-h-screen w-screen">
    <hero v-show="showHero" />

    <div class="flex flex-col pb-10 w-full mx-auto max-w-screen-xl" v-bind:style="{ marginTop: !showHero ? '50px' : '0px' }">
      <h2 class="text-secondaryBlack px-4 text-3xl font-sans-lato font-bold mb-5 leading-tight md:leading-normal">
        Latest Venues
        <nuxt-link :to="{ path: 'map' }" class="text-primaryGreen font-bold font-sans-lato text-base ml-1">View All &rarr;</nuxt-link>
      </h2>

      <div class="flex justify-between flex-wrap">
        <venue-card v-for="venue in venues" v-bind:key="'venue-' + venue.id" :venue="venue" />
      </div>
    </div>
  </div>
</template>

<script>
import Hero from '@/components/home/Hero.vue'
import VenueCard from '@/components/venues/VenueCard.vue'

export default {
  layout: 'App',
  name: 'home-page',

  transition(to, from) {
    if (process.env.mobile) {
      if (from != undefined && from.name != 'companies-new') {
        if (to.query['nav-bar'] && to.query['nav-bar'] == true) {
          return 'page'
        }
        return to.name == 'index' ? 'prev' : 'next'
      } else {
        return 'page'
      }
    } else {
      return 'page'
    }
  },

  data() {
    return {
      venues: {},
    }
  },

  async asyncData({ app }) {
    return {
      venues: await app.$api.venues.latest(),
    }
  },

  computed: {
    showHero() {
      return !process.env.mobile
    },
  },

  components: {
    Hero,
    VenueCard,
  },

  head() {
    return {
      title: 'Home',
    }
  },
}
</script>
